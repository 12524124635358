var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Docs
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c("transition", { attrs: { name: "slide" } }, [
                    _c(
                      "div",
                      [
                        _vm.showInputFile
                          ? _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "row",
                                        attrs: {
                                          label: _vm.FormMSG(16, "Add here:"),
                                          "label-for": _vm.buttonId,
                                          "label-cols": 3,
                                        },
                                      },
                                      [
                                        _c("b-form-file", {
                                          ref: "pdfFileId",
                                          attrs: {
                                            "browse-text": _vm.FormMSG(
                                              113,
                                              "Browse"
                                            ),
                                            name: "test",
                                            id: _vm.buttonId,
                                            accept: _vm.acceptFiles,
                                            placeholder:
                                              "Choose a file or drop it here...",
                                            "drop-placeholder":
                                              "Drop file here...",
                                            disabled:
                                              _vm.isSingleFile &&
                                              _vm.Docs.length === 1,
                                          },
                                          on: { change: _vm.submitDocFile },
                                          model: {
                                            value: _vm.pdfFile,
                                            callback: function ($$v) {
                                              _vm.pdfFile = $$v
                                            },
                                            expression: "pdfFile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12", xl: "12" } }, [
                              _vm.Docs.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("CardListBuilder", {
                                        attrs: {
                                          "custom-class": "hide-on-desktop",
                                          items: _vm.Docs,
                                          fields: _vm.docMobileFields,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "actions",
                                              fn: function (data) {
                                                return [
                                                  data.item.fileName.length > 0
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            size: "sm",
                                                            variant: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.viewDocument(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-eye",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        size: "sm",
                                                        variant: "danger",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDocument(
                                                            data.item.xid
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-trash",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2742688781
                                        ),
                                      }),
                                      _c("b-table", {
                                        ref: "myTable",
                                        staticClass: "hide-on-tablet",
                                        staticStyle: { "text-align": "left" },
                                        attrs: {
                                          hover: _vm.hover,
                                          responsive: "sm",
                                          selectable: "",
                                          selectedVariant: _vm.selectedColor,
                                          "select-mode": _vm.selectMode,
                                          items: _vm.Docs,
                                          "sticky-header": "400px",
                                          fields: _vm.docfields,
                                          "current-page": _vm.currentPage,
                                          "per-page": _vm.perPage,
                                          bordered: "",
                                          small: "",
                                          "head-variant": _vm.hv,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(attachToOffer)",
                                              fn: function (data) {
                                                return [
                                                  _c("b-form-checkbox", {
                                                    attrs: { size: "lg" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleAttachToOffer(
                                                          $event,
                                                          data.item.xid
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        data.item.attachToOffer,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data.item,
                                                          "attachToOffer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.item.attachToOffer",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(attachToOrder)",
                                              fn: function (data) {
                                                return [
                                                  _c("b-form-checkbox", {
                                                    attrs: { size: "lg" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleAttachToOrder(
                                                          $event,
                                                          data.item.xid
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        data.item.attachToOrder,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data.item,
                                                          "attachToOrder",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.item.attachToOrder",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(attachToInvoice)",
                                              fn: function (data) {
                                                return [
                                                  _c("b-form-checkbox", {
                                                    attrs: { size: "lg" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleAttachToInvoice(
                                                          $event,
                                                          data.item.xid
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        data.item
                                                          .attachToInvoice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data.item,
                                                          "attachToInvoice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.item.attachToInvoice",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(actions)",
                                              fn: function (data) {
                                                return [
                                                  data.item.fileName.length > 0
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            variant: "primary",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.viewDocument(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-eye",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDocument(
                                                            data.item.xid
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-trash",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3500748618
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _c("loading", {
                          attrs: { "is-full-page": "", active: _vm.isLoading },
                          on: {
                            "update:active": function ($event) {
                              _vm.isLoading = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }